<template>
    <div class="container h-100 my-2">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <router-link :to="{ name: 'Home' }" class="d-block mb-3"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
                <div class="box">
                <h2>{{ $t('compte.reinitialiser_mdp') }}</h2>
                    <form @submit.prevent="send">

                        <!-- Password -->
                        <div class="form-group">
                            <label for="name">{{ $t('compte.password') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input type="password" class="form-control" name="password" :placeholder="$t('compte.password')" v-model="form.password" :disabled="isWorking" autocomplete="off" required>
                            </div>
                        </div>

                        <!-- Confirm Password -->
                        <div class="form-group">
                            <label for="name">{{ $t('compte.password_confirmation') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input type="password" class="form-control" name="confirmPassword" :placeholder="$t('compte.password_confirmation')" v-model="form.confirmPassword" :disabled="isWorking" autocomplete="off" required>
                            </div>
                        </div>

                        <div>
                            {{ $t('compte.mdp_doit_contenir') }} :
                            <ul>
                                <li :class="password_lettre">{{ $t('compte.mdp_au_moins_une_lettre') }}</li>
                                <li :class="password_chiffre">{{ $t('compte.mdp_au_moins_un_chiffre') }}</li>
                                <li :class="password_symbole">{{ $t('compte.mdp_au_moins_symbole') }}</li>
                                <li :class="password_caracteres">{{ $t('compte.mdp_plus_6_caractere') }}</li>
                            </ul>
                        </div>

                        <b-alert v-if="errorInfos" variant="danger" show>
                            {{ errorInfos }}
                        </b-alert>

                        <!-- Bouton de validation du formulaire -->
                        <button type="submit" :disabled="!canSubmit" v-on:click="checkForm" class="mt-4 btn btn-primary btn-block rounded-pill">
                            {{ $t('global.reinitialiser') }}
                            <font-awesome-icon v-if="isWorking" class="mr-1" :icon="['fas', 'spinner']" spin />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import Navigation from '@/mixins/Navigation'

export default {
    name: 'PasswordUpdate',
    mixins: [User, Navigation],
    data: () => ({
        errorInfos: null,
        isWorking: false,
        form: {
            password: null,
            confirmPassword: null
        },
        canSubmit: false,
        password_lettre: 'password_red',
        password_chiffre: 'password_red',
        password_symbole: 'password_red',
        password_caracteres: 'password_red'
    }),
    methods: {
        async send() {
            this.errorInfos = null
            this.isWorking = true

            if (this.form.password !== this.form.confirmPassword) {
                this.errorInfos = this.getTrad('error.IPWC')
                this.isWorking = false
                return
            }

            try {
                await this.updatePassword(this.$route.params.email, this.$route.params.token, this.form.password)
                const domain = this.$route.query.domain
                if(domain && domain !== window.location.origin) {
                    window.location.replace(domain)
                }
                else {
                    await this.loginUser(this.$route.params.email, this.form.password)
                }
            } catch (err) {
                this.errorInfos = err.message
            } finally {
                this.isWorking = false
            }
        },

        checkPassword(password) {
            this.canSubmit = true
            if (password.match(/[a-zA-Z]/)){
                this.password_lettre = 'password_green'
            } else {
                this.password_lettre = 'password_red'
                this.canSubmit = false
            }

            if (password.match(/\d/)){
                this.password_chiffre = 'password_green'
            } else{
                this.password_chiffre = 'password_red'
                this.canSubmit = false
            }

            if (password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/)){
                this.password_symbole = 'password_green'
            } else{
                this.password_symbole = 'password_red'
                this.canSubmit = false
            }

            if (password.length >= 6){
                this.password_caracteres = 'password_green'
            } else{
                this.password_caracteres = 'password_red'
                this.canSubmit = false
            }
        }
    },
    watch: {
        'form.password' (val) {
            this.checkPassword(val)
        }
    }
}
</script>